import axios from 'axios';
import { BaseV1ApiService } from '@/services/backend/baseV1';

export class Fl44CabinetContracts extends BaseV1ApiService {
    constructor() {
        super('cabinet/customer/contracts');
    }

    async getList(filters) {
        try {
            const response = await axios.get(this.getUrl('fl44'), { params: filters });
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    getContractsXlsExport(filters) {
        return axios.post(this.getUrl('xls'), filters, { responseType: 'blob' });
    }
}
